import shopifyGenericDefaults from '../shopify-generic/config.js';

export default {
  includes: ['shopify-generic'],
  ...shopifyGenericDefaults,
  defaultView: 'active1',
  pagination: {
    edgeRange: 0,
    centralRange: 2,
    disableDelimeters: true,
  },
};
