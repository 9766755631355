
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function relatedItemsRT () {
    function repeatItems1(items, itemsIndex) {
        return [items(function () {
                function mergeProps(inline, external) {
                    var res = Object.assign({}, inline, external);
                    if (inline.hasOwnProperty('style')) {
                        res.style = _.defaults(res.style, inline.style);
                    }
                    if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                        res.className = external.className + ' ' + inline.className;
                    }
                    return res;
                }
                return _createElement('div', { 'className': 'card' }, _createElement('a', { 'href': this.url }, this.on_sale && this.compare_at_price > this.price ? _createElement('span', {
                    'className': 'number',
                    'key': '47'
                }, '\n      -', this.calcDiscount(this.price, this.compare_at_price), '%\n    ') : null, _createElement('img', {
                    'src': this.imageOrDefault(this.resizeImage(this.image)),
                    'alt': this.removeHTML(this.title),
                    'onError': this.onImageError,
                    'loading': 'lazy'
                }), _createElement('div', { 'className': 'info' }, _createElement('p', mergeProps({ 'className': 'ssc-title-slide' }, { dangerouslySetInnerHTML: { __html: this.title } })), _createElement('div', { 'className': 'product-price' }, this.on_sale ? _createElement('s', {
                    'className': 'product-price__price custom-price-sale',
                    'key': '524'
                }, _createElement('span', { 'className': 'money' }, ' ', this.formatPrice(this.compare_at_price), ' ')) : null, _createElement('span', { 'className': 'product-price__price product-price__sale' }, _createElement('span', { 'className': 'money' }, this.price_varies ? _createElement('span', { 'key': '797' }, 'Starting at: ') : null, ' ', this.formatPrice(this.price), '\n          '))))), _createElement('div', { 'className': 'junip-product-summary-wrapper' }, _createElement('span', {
                    'className': 'junip-product-summary',
                    'data-product-id': this.id
                }), _createElement('a', {
                    'href': this.url + '#reviews',
                    'className': 'text-reviews'
                }, 'See all reviews')));
            }, { count: undefined })];
    }
    return _createElement('div', { 'className': 'cm_related-products' }, _createElement('div', { 'className': 'cm_related-products-title-container' }, _createElement('h2', { 'className': 'cm_related-products-title' }, 'You might also like...')), _createElement.apply(this, [
        'div',
        { 'className': 'cm_related-products-list cmRepeater_items' },
        _map(this.items, repeatItems1.bind(this))
    ]));
}
        export const componentNames = []